import { MutableRefObject, useCallback, useRef, useState } from "react";
import isFunction from "lodash.isfunction";

export default function useStateRef<T>(defaultValue: T): [T, MutableRefObject<T>, (val: T | ((currentValue: T) => T)) => void] {
    const [value, setValue] = useState<T>(defaultValue);
    const valueRef = useRef(defaultValue);

    const handleSetValue = useCallback((val: T | ((currentValue: T) => T)) => {
        if (isFunction(val)) {
            setValue((currentValue) => {
                const retVal = val(currentValue);

                valueRef.current = retVal;

                return retVal;
            });
            return;
        }

        valueRef.current = val;
        setValue(val);
    }, [setValue, valueRef]);

    return [value, valueRef, handleSetValue];
}
