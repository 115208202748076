import React, { useEffect, useMemo } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import LoginForm from './components/login/LoginForm';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import SecureRoutes from './components/security/SecureRoutes';
import RegisterForm from './components/register/RegisterForm';

function App() {
    const theme = useMemo(() => {
        return createTheme({
            palette: {
                mode: 'dark',
            },
        });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/systems/:systemId" element={<LoginForm/>}/>
                    <Route path="/systems/:systemId/register" element={<RegisterForm/>}/>
                </Routes>
                <SecureRoutes/>
            </BrowserRouter>
            <CssBaseline/>
        </ThemeProvider>
    );
}

export default App;
