import React, { useCallback, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import useSyncedRef from '../../utils/hooks/useSyncedRef';
import loginBasic from '../../api/auth/loginBasic';
import getUserToken from '../../api/auth/getUserToken';
import getPostMessageInterface from '../../utils/getPostMessageInterface';
import useSystemSettings from '../../utils/hooks/useSystemSettings';

const propTypes = {

};

const LoginForm: React.FunctionComponent<InferProps<typeof propTypes>> = () => {
    const { systemId } = useParams<{ systemId: string }>();
    const navigate = useNavigate();

    const [systemSettings, isFetched] = useSystemSettings(systemId);
    console.log('systemSettings', systemSettings);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [locked, setLocked] = useState(false);

    const handleUsernameChange = useCallback((event) => {
        setUsername(event.target.value);
    }, [setUsername]);

    const handlePasswordChange = useCallback((event) => {
        setPassword(event.target.value);
    }, [setPassword]);

    const handleRegisterClick = useCallback(() => {
        navigate(`/systems/${systemId}/register`);
    }, [navigate, systemId]);

    const usernameRef = useSyncedRef(username);
    const passwordRef = useSyncedRef(password);

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();

        try {
            if (!systemId) {
                throw new Error('no systemId');
            }

            setLocked(true);

            const refreshTokenData = await loginBasic(systemId, usernameRef.current, passwordRef.current);
            if (!refreshTokenData.body?.token) {
                throw new Error('could not get refresh-token');
            }

            const userToken = await getUserToken(systemId, refreshTokenData.body.token);
            if (!userToken) {
                throw new Error('could not get user-token');
            }

            const data = {
                access_token: userToken.token,
                expires: userToken.expires,
                refresh_token: refreshTokenData.body.token,
                token_type: 'bearer',
            };

            const postMessageInterface = getPostMessageInterface();
            if (postMessageInterface) {
                postMessageInterface.postMessage({
                    type: 'login',
                    payload: data,
                }, '*');
            }
        } finally {
            setLocked(false);
        }
    }, [usernameRef, passwordRef, setLocked, systemId]);

    return (
        <Paper
            sx={{
                display: 'block',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: `100%`,
                width: 400,
                padding: 3,
            }}
        >
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                        fontSize: '1.5rem',
                        textAlign: 'center',
                        mb: 2
                    }}
                >
                    Anmeldung
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        id="username"
                        label="Benutzername"
                        variant="outlined"
                        value={username}
                        onChange={handleUsernameChange}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <TextField
                        id="password"
                        type="password"
                        label="Kennwort"
                        variant="outlined"
                        value={password}
                        onChange={handlePasswordChange}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'inline-block',
                            position: 'relative',
                            alignSelf: 'center',
                            mt: 3,
                        }}
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={locked}
                        >
                            Anmelden
                        </Button>
                        {locked && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                    {process.env.NODE_ENV === 'development' && (
                        <Box
                            sx={{
                                display: 'inline-block',
                                position: 'relative',
                                alignSelf: 'center',
                                mt: 3,
                            }}
                        >
                            <Button
                                variant="contained"
                                disabled={locked}
                                onClick={() => getPostMessageInterface()?.postMessage({
                                    type: 'login',
                                    payload: {
                                        access_token: 'XXXX',
                                        expires: -1,
                                        refresh_token: 'YYYYY',
                                        token_type: 'bearer',
                                    },
                                }, '*')}
                            >
                                Anmelden (DEBUG)
                            </Button>
                        </Box>
                    )}
                </Box>
            </form>
            {systemSettings?.registration?.enable && (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        mt: 1,
                    }}
                >
                    <Button
                        variant="contained"
                        onClick={handleRegisterClick}
                    >
                        Registrieren
                    </Button>
                </Box>
            )}
        </Paper>
    );
};

LoginForm.propTypes = propTypes;

export default LoginForm;
