import React, { useCallback, useState } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Paper, TextField, Typography } from '@mui/material';
import isEmail from 'validator/lib/isEmail';
import useStateRef from '../../utils/hooks/useStateRef';
import createUser from '../../api/auth/createUser';

const propTypes = {

};

const RegisterForm: React.FunctionComponent<InferProps<typeof propTypes>> = () => {
    const { systemId } = useParams<{ systemId: string }>();

    const [givenName, givenNameRef, setGivenName] = useStateRef('');
    const [familyName, familyNameRef, setFamilyName] = useStateRef('');
    const [eMail, eMailRef, setEMail] = useStateRef('');
    const [username, usernameRef, setUsername] = useStateRef('');
    const [password, passwordRef, setPassword] = useStateRef('');
    const [password2, password2Ref, setPassword2] = useStateRef('');
    const [locked, setLocked] = useState(false);

    const handleUsernameChange = useCallback((event) => {
        setUsername(event.target.value);
    }, [setUsername]);

    const handlePasswordChange = useCallback((event) => {
        setPassword(event.target.value);
    }, [setPassword]);

    const handlePassword2Change = useCallback((event) => {
        setPassword2(event.target.value);
    }, [setPassword2]);

    const handleGivenNameChanged = useCallback((event) => {
        setGivenName(event.target.value);
    }, [setGivenName]);

    const handleFamilyNameChanged = useCallback((event) => {
        setFamilyName(event.target.value);
    }, [setFamilyName]);

    const [isEmailValid, setIsEmailValid] = useState(false);

    const handleEMailChanged = useCallback((event) => {
        const value = event.target.value;

        setIsEmailValid(isEmail(value));

        setEMail(value);
    }, [setEMail, setIsEmailValid]);

    const navigate = useNavigate();

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();

        try {
            if (!systemId) {
                throw new Error('no systemId');
            }

            if (passwordRef.current !== password2Ref.current) {
                throw new Error('non matching password');
            }

            const body = {
                givenName: givenNameRef.current,
                familyName: familyNameRef.current,
                email: eMailRef.current,
                username: usernameRef.current,
                password: passwordRef.current,
            };

            try {
                await createUser(systemId, body);

                navigate(`/systems/${systemId}`);
            } catch (e) {
                console.error(e);
            }

            setLocked(true);
        } finally {
            setLocked(false);
        }
    }, [
        navigate,
        setLocked,
        systemId,
        givenNameRef,
        familyNameRef,
        eMailRef,
        usernameRef,
        passwordRef,
        password2Ref,
    ]);

    return (
        <Paper
            sx={{
                display: 'block',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                maxWidth: `100%`,
                maxHeight: '95vh',
                overflow: 'auto',
                width: 400,
                padding: 3,
            }}
        >
            <form onSubmit={handleSubmit}>
                <Typography
                    variant="h1"
                    component="h1"
                    sx={{
                        fontSize: '1.5rem',
                        textAlign: 'center',
                        mb: 2
                    }}
                >
                    Registrierung
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        id="givenname"
                        label="Vorname"
                        variant="outlined"
                        value={givenName}
                        onChange={handleGivenNameChanged}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <TextField
                        id="familyname"
                        label="Nachname"
                        variant="outlined"
                        value={familyName}
                        onChange={handleFamilyNameChanged}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <TextField
                        id="email"
                        label="eMail"
                        variant="outlined"
                        value={eMail}
                        onChange={handleEMailChanged}
                        helperText={!isEmailValid ? 'Gebe eine richtige eMail-Adresser ein.' : undefined}
                        error={!isEmailValid}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <TextField
                        id="username"
                        label="Benutzername"
                        variant="outlined"
                        value={username}
                        onChange={handleUsernameChange}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <TextField
                        id="password"
                        type="password"
                        label="Kennwort"
                        variant="outlined"
                        value={password}
                        onChange={handlePasswordChange}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <TextField
                        id="password2"
                        type="password"
                        label="Kennwort wiederholen"
                        variant="outlined"
                        value={password2}
                        onChange={handlePassword2Change}
                        error={password !== password2}
                        sx={{
                            width: 400,
                            maxWidth: '100%',
                            mt: 2,
                            mb: 2,
                        }}
                    />
                    <Box
                        sx={{
                            display: 'inline-block',
                            position: 'relative',
                            alignSelf: 'center',
                            mt: 3,
                        }}
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={locked}
                        >
                            Registrieren
                        </Button>
                        {locked && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </Box>
            </form>
        </Paper>
    );
};

RegisterForm.propTypes = propTypes;

export default RegisterForm;
