import request, { HttpMethod } from '../../utils/request';
import { AUTH_BASE_URL } from '../../constants/api-constants';

export type CreateDto = {
    username: string;
    password: string;
    email: string;
    givenName: string;
    familyName: string;
};

export default async function createUser(systemId: string, createDto: CreateDto) {
    const res = await request(`${AUTH_BASE_URL}/systems/${systemId}/users`, HttpMethod.POST, createDto);

    if (res.status !== 201) {
        throw new Error('could not create user');
    }

    return res;
}
