export default function getPostMessageInterface(): ({ postMessage: typeof window.postMessage } | null) {
    if (window.opener) {
        return window.opener;
    }

    if (window.parent !== window) {
        return window.parent;
    }

    return null;
}
