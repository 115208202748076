import type { SystemSetting } from '../../types/SystemSetting';
import { useEffect, useState } from 'react';
import request from '../request';

const BASE_REQUEST_URL = 'https://auth.vimuro.cloud/api';

export default function useSystemSettings(systemId: string | undefined): ([SystemSetting, true] | [null, false]) {
    const [isFetched, setIsFetched] = useState(false);
    const [data, setData] = useState<SystemSetting | null>(null);

    useEffect(() => {
        (async () => {
            if (!systemId) {
                return;
            }

            const res = await request<SystemSetting>(`${BASE_REQUEST_URL}/v1.0/systems/${systemId}`);

            if (res.status === 200 && res.body) {
                setData(res.body);
                setIsFetched(true);
            }
        })();
    }, []);

    return [data as any, isFetched];
}
