import request, { HttpMethod } from '../../utils/request';
import { AUTH_BASE_URL } from '../../constants/api-constants';

export type TokenResponse = {
    token: string;
    expiresAt: number,
};

export default async function getUserToken(systemId: string, token: string) {
    const res = await request<TokenResponse & { expires: number }>(`${AUTH_BASE_URL}/systems/${systemId}/tokens`, HttpMethod.GET, null, {
        Authorization: `Bearer ${token}`,
    });

    if ((res.status === 200 || res.status === 201) && res.body) {
        return {
            token: res.body.token,
            expires: new Date(res.body.expiresAt * 1000),
        };
    }

    return null;
}
