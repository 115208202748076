export enum HttpMethod {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}

export default async function request<T>(url: string, method: HttpMethod | string = HttpMethod.GET, body?: any, customHeaders?: any): Promise<{ status: number, body?: T, headers: Headers }> {
    const headers = new Headers();

    if (customHeaders) {
        Object.entries(customHeaders).forEach(([key, value]) => {
            if (!value) return;

            headers.set(key, value as string);
        });
    }

    const options: RequestInit = {
        method,
        headers,
    };

    if (body) {
        if (!headers.get('Content-Type')) {
            headers.set('Content-Type', 'application/json');
        }
        options.body = JSON.stringify(body);
    }

    const response = await fetch(url, options);
    const status = response.status;
    // @ts-ignore
    let responseBody: T = undefined;

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
        responseBody = await response.json();
    }

    return {
        status,
        body: responseBody,
        headers: response.headers,
    };
}
