import React, { useEffect } from 'react';
import PropTypes, { InferProps } from 'prop-types';
import { useNavigate } from "react-router-dom";
import getPostMessageInterface from '../../utils/getPostMessageInterface';

const propTypes = {

};

const SecureRoutes: React.FunctionComponent<InferProps<typeof propTypes>> = () => {
    const navigate = useNavigate();

    const postMessageInterface = getPostMessageInterface();
    useEffect(() => {
        if (!postMessageInterface && process.env.NODE_ENV !== 'development') {
            navigate('/', {
                replace: true,
            });
        }
    }, [navigate, !!postMessageInterface]);

    return null;
};

SecureRoutes.propTypes = propTypes;

export default SecureRoutes;
