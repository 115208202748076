import request, { HttpMethod } from '../../utils/request';
import { AUTH_BASE_URL } from '../../constants/api-constants';

type BasicLoginResponse<T> = {
    token: string;
    expiresAt: T;
}

export enum TokenScope {
    offline_access = 'offline_access',
}

export default async function loginBasic(systemId: string, username: string, password: string) {
    const res = await request<BasicLoginResponse<number>>(`${AUTH_BASE_URL}/systems/${systemId}/tokens?scope=${[TokenScope.offline_access].join(',')}`, HttpMethod.POST, null, {
        Authorization: `Basic ${btoa(`${username}:${password}`)}`,
    });

    if (res.status !== 201 || !res.body?.token) {
        throw new Error('no vimuro.cloud login response');
    }

    return res;
}
